import React, { useState } from "react"
import Layout from "../components/reusable-components/layout"
import SEO from "../components/seo"
import { I18nProvider, LOCALES } from "../i18n"

const NotFoundPage = () => {
  const [activeLangue, setActiveLangue] = useState(LOCALES.ENGLISH)
  return (
    <I18nProvider locale={activeLangue}>
      <Layout>
        <SEO title="404: Not found" />
        <h1>404: Not Found</h1>
        <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
      </Layout>
    </I18nProvider>
  )
}

export default NotFoundPage
